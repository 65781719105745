import React                                        from 'react';
import { Redirect }                                 from 'react-router-dom';
import {withSnackbar}                               from 'notistack';
import moment                                       from 'moment';
import MomentUtils                                  from '@date-io/moment';
import Grid                                         from '@material-ui/core/Grid';
import Link                                         from '@material-ui/core/Link';
import Typography                                   from '@material-ui/core/Typography';
import Button                                       from '@material-ui/core/Button';
import Hidden                                       from '@material-ui/core/Hidden';
import ArrowUp                                      from '@material-ui/icons/ArrowUpward';
import ArrowDown                                    from '@material-ui/icons/ArrowDownward';
import { DatePicker, MuiPickersUtilsProvider }      from "@material-ui/pickers";
import IconButton                                   from '@material-ui/core/IconButton';
import ClearIcon                                    from "@material-ui/icons/Clear";

import AutoSelect                                   from 'components/atoms/AutoSelect';
import TopNav                                       from 'components/molecules/TopNav';
import MobileNav                                    from 'components/molecules/MobileNav';
import apis                                         from 'common/apis';
import Log                                          from 'common/log';
import utility                                      from 'common/utility';
import {errorMsg}                                   from 'common/errors';
import path                                         from 'common/path';
import translations                                 from 'common/translations';
import authStore                                    from 'redux/authStore';
import jclassStore, {myLessonsList, selectedJclass} from 'redux/jclassStore';
import commonStore                                  from 'redux/commonStore';
import ClassCard                                    from './ClassCard';
import Skeleton                                     from './Skeleton';

const log = Log('Profile', 'info');

class JapaneseClass extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			loading        : false,
			mobileNav      : false,
			isFetched      : false,
			selectedJclass : null,
			participationUrl : false,
			selectedDay: null,
			attendancePoints: 0,
			lessons        : [],
			jclasses       : [],
			exams          : [],
			selectedExam   : null,
			langCode       : 'en',
			sortyBy        : 1, //dsc
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = async () => {
		
		this.jclassUnsub = jclassStore.subscribe (this.getMyLessons);
		this.commonUnsub = commonStore.subscribe (this.getLanguage);
		this.getLanguage ();
		this.getMyLessons ();
	}

	componentWillUnmount = () => {
		
		this.jclassUnsub ();
	}

	getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		this.setState ({
			langCode
		});
	}

	clearDate = () => {
		this.setState ({
			selectedDay : null,
		});
	}

	handleDateChange = (date) => {
		if(!date) {
			return;
		}
		this.setState ({
			selectedDay : date._d,
		});
	}

	getMyLessons = async () => {

		let authInfo = authStore.getState ();
		let applicant = authInfo.applicant;

		if (!applicant) {
			return;
		}

		let jclassInfo = jclassStore.getState ();
		let lessons    = jclassInfo.myLessons;

		if (!lessons.length && !this.state.isFetched) {
			this.setState ({
				loading : true,
			});

			let data = {
				email : applicant.email,
			};
			let result;
			try {
				result = await apis.myLessons (data);
				log.info ({lessons : result}, 'my lessons get ok');
			}
			catch (err) {
				log.error ({err}, 'error getting lessons info');
				if (err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.setState ({
					loading : false,
				});
				return;
			}
			if (result.errors) {
				this.notify (result.errors, {variant : 'error'});
				return;
			}

			if (!result.length) {
				this.setState ({
					lessons   : result,
					isFetched : true,
					loading   : false,
					jclasses  : [],
				});
				return;
			}

			let jclasses = [];
			let sortedResult = [];
			for (let i = 0; i < result.length; i++) {
				let sorted = utility.sortData (result[i].lessons, 'date', 1);
				result[i].lessons = sorted;
				jclasses.push ({
					jclass_id   : result[i].jclass_id,
					jclass_name : result[i].jclass_name,
				});
				sortedResult.push(result[i]);
			}

			let __selectedJclass = {label : jclasses[0].jclass_name, value : jclasses[0].jclass_id};
			let __lesson = result.find (l => l.jclass_id === jclassInfo.selectedJclass?.value);
			let exams =[];
			let participationUrl;
			let attendancePoints = 0;
			if (__lesson) {
				exams = __lesson.exams;
				participationUrl = __lesson.participation_url;
				attendancePoints = __lesson.total_participation_point;
			}
			jclassStore.dispatch (selectedJclass (__selectedJclass));
			jclassStore.dispatch (myLessonsList (sortedResult));

			this.setState ({
				selectedJclass : __selectedJclass,
				lessons   : result,
				isFetched : true,
				loading   : false,
				exams     : exams,
				jclasses  : jclasses,
				participationUrl,
				attendancePoints
			});
			return;
		}
		let jclasses = [];
		for (let i = 0; i < lessons.length; i++) {
			jclasses.push ({
				jclass_id   : lessons[i].jclass_id,
				jclass_name : lessons[i].jclass_name,
			});
		}
		let __lesson = jclassInfo.myLessons.find (l => l.jclass_id === jclassInfo.selectedJclass?.value);
		let exams =[];
		let participationUrl;
		if (__lesson) {
			exams = __lesson.exams;
			participationUrl = __lesson.participation_url;
		}
		this.setState ({
			exams,
			lessons,
			jclasses,
			participationUrl,
			selectedJclass : jclassInfo.selectedJclass,
		});
	}

	handleSelect = (name, value) => {
		
		let __lesson = this.state.lessons.find (l => l.jclass_id === value.value);
		let exams =[];
		if (__lesson) {
			exams = __lesson.exams;
		}
		this.setState ({
			selectedJclass : value,
			attendancePoints : __lesson?.total_participation_point,
			participationUrl : __lesson.participation_url,
			exams,
		});
		jclassStore.dispatch (selectedJclass (value));
	}

	handleExamChange = (name, value) => {
		this.setState ({
			selectedExam  : value.value,
		});
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	handleSort = () => {
		this.setState ({
			sortyBy : 0 - this.state.sortyBy
		});
	}

	renderLessons = () => {
		if (!this.state.selectedJclass) {
			return;
		}

		const filteredLessons = this.state.lessons
			.find(l => l.jclass_id === this.state.selectedJclass.value)
			.lessons
			.filter(lesson => !this.state.selectedDay || moment(lesson.date).isSame(moment(this.state.selectedDay), 'day'));

		if (this.state.selectedDay && filteredLessons.length === 0) {
			return (
				<Typography variant="h6" align="center" style={{ marginTop: '20px' }}>
					{ translations.jClass.noLesson[this.state.langCode]}
				</Typography>
			);
		}

		return filteredLessons.map((lesson) => (
			<ClassCard langCode={this.state.langCode} lesson={lesson} key={lesson.lesson_id} jclassId={this.state.selectedJclass.value} />
		));
	}

	render () {

		if (this.state.selectedExam) {
			return (
				<Redirect to = {`${path.exam}/${this.state.selectedExam}`}/>
			);
		}
		
		return (
			<Grid className = 'parent-content'>
				<TopNav name = '' mobileNav = {this.handleMobileNav}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					{this.state.loading ?
						<Skeleton />
						:
						<Grid>
							{!this.state.lessons.length ? <Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b'}}> There is currently no lessons. </Typography> :
								<Grid>
									<Grid container spacing = {2}>
										<Grid item xs = {9} sm = {4} md = {3} lg = {2} xl = {2}>
											<AutoSelect
												value      = {this.state.selectedJclass}
												options    = {utility.autoSelectOptions (this.state.jclasses, 'jclass_name', 'jclass_id')}
												name       = 'jclass'
												searchable = {false}
												onChange   = {this.handleSelect}
												className  = 'mb-12'
											/>
										</Grid>
										<Grid item xs = {3} sm = {3} md = {1} lg = {2} xl = {1}>
											<Button onClick = {this.handleSort}>Sort By {this.state.sortyBy === -1 ? <ArrowUp /> : <ArrowDown/> } </Button>
										</Grid>
										<Grid item xs = {12} sm = {12} md = {4} lg = {4} xl = {5}>
											{this.state.participationUrl ?
												<Link href = {this.state.participationUrl} color = 'primary' target = '_blank' style = {{fontSize : 18}}>
													Please click here to join the lecture online
												</Link>
												: null}
										</Grid>
										<Grid item xs = {12} sm = {12} md = {4} lg = {4} xl = {4} style = {{display : 'flex', alignItems : 'baseline', justifyContent : 'flex-end'}}>
											<strong className = 'mr-12' style = {{fontSize : 16}}> {this.state.exams.length ? 'Take the test → ' : ''} </strong>
											<div style = {{width : 'calc(100% - 130px)'}}>
												<AutoSelect
													options    = {utility.autoSelectOptions (this.state.exams, 'exam_name', 'exam_id')}
													name       = 'exams'
													searchable = {false}
													onChange   = {this.handleExamChange}
													className  = 'mb-12'
													placeholder= 'Select Exam'
												/>
											</div>
										</Grid>
									</Grid>
									<Grid className='mt-12 mb-12' container justify='space-between' alignItems='center'>
										<Typography variant = 'h6' color='error'> { translations.jClass.attendance_points[this.state.langCode]} : {this.state.attendancePoints} </Typography>
										<Grid>
											<MuiPickersUtilsProvider utils={MomentUtils} locale={this.state.langCode === 'en' ? 'en' : 'ja'}>
												<DatePicker
													autoOk
													label = {translations.jClass.selectDate[this.state.langCode]}
													views={["year", "month", "date"]}
													value={this.state.selectedDay}
													onChange={this.handleDateChange}
												/>
											</MuiPickersUtilsProvider>
											{this.state.selectedDay ?
												<IconButton size = 'small' color = 'primary' style = {{position : 'relative', top : '8px'}} onClick = {this.clearDate}> <ClearIcon /> </IconButton>
												: null}
										</Grid>
									</Grid>
								</Grid>
							}
							<Grid container direction={this.state.sortyBy === 1 ? 'column' : 'column-reverse'}>
								{this.renderLessons()}
							</Grid>
						</Grid>
					}
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(JapaneseClass);
